import React , { useState,useEffect }  from 'react';
import { useDropzone } from 'react-dropzone';
import { MdCloudUpload,MdDelete  } from 'react-icons/md';
import './MyDropzone.css';
import {IconButton,Tooltip,Icon,Typography} from '@mui/material';

function MyDropzone({filedocum,fileorigin,statecam,takestatecam}) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {'image/*':[], 'application/pdf': ['.pdf']},
    onDrop: acceptedFiles => {
      acceptedFiles.forEach(file => {
        if (!file.type.startsWith('image/') && !file.type.startsWith('application/pdf')) {
          alert('Solo se permiten archivos de imagen y PDF.');
          return;
        }
        //setFiles(oldFiles => [...oldFiles, file]);
        setFiles([file]);
        filedocum(file);
        //console.log(file);
      });
    }
  });
  const removeFile = (index) => {
    setFiles(oldFiles => oldFiles.filter((file, i) => i !== index));
    filedocum(null);
  }
    useEffect(() => {   
    if(fileorigin!=='null'&&fileorigin!==undefined && fileorigin)
       {
        setFiles([fileorigin]);
      }else if(fileorigin==null){
        setFiles(oldFiles => oldFiles.filter((file, i) => i !== 0));
      }
  }, [fileorigin]);
  const take=()=>{
    takestatecam();
  }
  return (
    <div>
      <p style={{display:'none'}} className='title'>Sube tu identificación aqui</p>
      <div style={{display:'none'}} {...getRootProps()} className={isDragActive ? 'dropzone-container-drag-active' : 'dropzone-container'}>
        <input {...getInputProps()} />
        <MdCloudUpload className={isDragActive ? 'icon-drag-active' : 'icon'} />
        {
          isDragActive ?
            <p>Suelta los archivos aquí...</p> :
            <p>Arrastra y suelta algunos archivos aquí, o haz clic para seleccionar archivos</p>
        }
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}> 
                <Tooltip key={0} title={'Tomar Foto'} arrow>
                   { !statecam ? <IconButton onClick={take} style={{ display:'flex',marginRight: '0px', flexDirection: 'column', alignItems: 'center',borderRadius:'8px',color: '#0087F7'}}>
                        <Icon>{'remember_me'}</Icon>
                        <Typography variant="caption" style={{ marginTop: '4px',color:'black',fontWeight:'bold'}}>
                                {'Tomar Foto Identificacion'}
                            </Typography>
                    </IconButton> :
                    <IconButton onClick={take} style={{ display:'flex',marginRight: '0px', flexDirection: 'column', alignItems: 'center',borderRadius:'8px',color: '#0087F7'}}>
                    <Icon>{'person'}</Icon>
                    <Typography variant="caption" style={{ marginTop: '4px',color:'black',fontWeight:'bold'}}>
                            {'Tomar Foto Cara'}
                        </Typography>
                </IconButton>}
                </Tooltip>
                </div> 
      <ul>
        {files.map((file, index) => (
          <li key={index}>
            {file.name}
            <button onClick={() => removeFile(index)}><MdDelete /></button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default MyDropzone;
