import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import './Camera.css';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faCamera, faRepeat, faPause } from '@fortawesome/free-solid-svg-icons';
import * as faceapi from 'face-api.js';
//import ToggleSwitch from './component/Toggle';
import {IconButton,Tooltip,Icon,Typography} from '@mui/material';


const Camera = ({ photo,photorigin,setphotorigin,statecam,filedocum}, ref) => {


  const [stream, setStream] = useState(null);
  const videoHeight = 380;
  const videoWidth = 540;
  const videoRef = useRef();
  const canvasRef = useRef();
  const containerRef = useRef();
  const [isChecked, setIsChecked] = useState(false);
  const statecamRef = useRef(statecam);
  useEffect(() => {
    statecamRef.current = statecam;  
    if(!statecam){
      handleVideoOnPlay();
    }
  }, [statecam]);
  const loadModels = async () => {
    const MODEL_URL = '/models';
    await faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL);
    await faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL);
     await faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL);
    await faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL);
  };
  const drawRoundedRect = (ctx, x, y, width, height, radius) => {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
  };
  useImperativeHandle(ref, () => ({
    takepausa,takenewd
  }));

  const handleVideoOnPlay = () => {
    const intervalId = setInterval(() => {
        
    //setInterval(async () => { 
      //if (videoRef.current) {  
        if (videoRef.current ) {   
          if (!canvasRef.current) {
            canvasRef.current = faceapi.createCanvasFromMedia(videoRef.current);
            containerRef.current.append(canvasRef.current); 
          }
        const displaySize = { width: videoWidth, height: videoHeight };
        faceapi.matchDimensions(canvasRef.current, displaySize);
        faceapi.detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions())
        .withFaceLandmarks()
        .withFaceExpressions()
        .then(detections => {
            if (statecamRef.current){
              const ctx = canvasRef.current.getContext('2d');
              ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
              const adjustedWidth = 475;
              const adjustedHeight = 280;
              const adjustedX = 30;
              const adjustedY = 45;  
              ctx.strokeStyle = 'skyblue';
              ctx.lineWidth = 4;
              drawRoundedRect(ctx, adjustedX, adjustedY, adjustedWidth, adjustedHeight, 10);
              ctx.stroke();
            clearInterval(intervalId);
            }else{
          const resizedDetections = faceapi.resizeResults(detections, displaySize);
          const ctx = canvasRef.current.getContext('2d');
          ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  
          for (const detection of resizedDetections) { 
            const { box: { x, y, width, height } } = detection.detection;
            // Ajuste las dimensiones del rectángulo
            const adjustment = 0.3;  // Ajuste el tamaño del cuadro en un 10%
            const adjustedWidth = width * (1 - adjustment);
            const adjustedHeight = height * (1 - adjustment);
            const adjustedX = x + width * adjustment / 2;
            const adjustedY = y + height * adjustment / 2;
  
            ctx.strokeStyle = 'skyblue';
            ctx.lineWidth = 4;
            drawRoundedRect(ctx, adjustedX, adjustedY, adjustedWidth, adjustedHeight, 10);
            ctx.stroke();
          }
          }

        })
        .catch(error => {
          console.error("Error detectando caras:", error);
        });
        //const detections = await faceapi.detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions();
        //console.log(detections);
      }
    }, 1250);
    return () => clearInterval(intervalId);
  };
  useEffect(() => {
    let mounted = true;
    const playVideo = () => {
      if (mounted && videoRef.current) { 
        videoRef.current.play()
          .catch(err => console.log("Error al intentar reproducir el video:", err));
      }
    };
    const unifiedInit = async () => {
      try {
        await loadModels();
  
        if (mounted && !photorigin) {
          let stream;
          if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            stream = await navigator.mediaDevices.getUserMedia({
              audio: false,
              video: {
                facingMode: 'user',
                width: videoWidth,
                height: videoHeight,
              },
            });
            setStream(stream);
            const video = videoRef.current;
            video.srcObject = stream;
  
            if (video.readyState >= 4) {
              playVideo();
            } else {
              video.addEventListener('canplay', playVideo);
            }
          }
        }
      } catch (err) {
        console.error("Error:", err);
      }
    };
    unifiedInit(); 
    return () => {
      mounted = false;
      videoRef.current && videoRef.current.removeEventListener('canplay', playVideo);
    };
  }, []);

  const takePhoto = () => {
    try {
      let video = videoRef.current;
      let canvas = canvasRef.current;
      let context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      setTimeout(() => video.pause(), 50);
      //photo=canvas.toDataURL();
      const dataURL = canvas.toDataURL();
      const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = dataURL;
      if(statecam){
        fetch(dataURL)
        .then(res => res.blob()) // Obtienes el blob del src de la imagen
        .then(blob => {
          const file1 = new File([blob], 'filedocum.jpg', {type: 'image/jpeg'});
          filedocum(file1);
        });
      }else{
        //const img = new Image();
        //img.crossOrigin = "Anonymous";
        //img.src = dataURL;
        photo(img.src);
      }
    } catch (error) {
      
    }  
  };
  const takenew = () => {
    setphotorigin(null);
    navigator.mediaDevices.getUserMedia({   video: {
      facingMode: 'user',
      width: videoWidth,
      height: videoHeight,
    } })
      .then(stream => {
        setStream(stream);
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch(err => {
        console.log("Algo salió mal!", err);
      });
  }
  const takenewd = () => {
    navigator.mediaDevices.getUserMedia({   video: {
      facingMode: 'user',
      width: videoWidth,
      height: videoHeight,
    } })
      .then(stream => {
        setStream(stream);
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch(err => {
        console.log("Algo salió mal!", err);
      });
  }
  const takepausa = () => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      setStream(null);  // resetea el estado del stream
      photo(null);
    }
    let video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, video.width, video.height);
    video.srcObject = null;
    //video.load();
  }
  const actions = [
    { label: "Recargar", callback: () => takenew(),icon:'autorenew',display:"flex" },
    { label: "Tomar foto", callback: () => takePhoto(),icon:'photo_camera',display:"flex" },
    { label: "Pausar", callback: () => takepausa(),icon:'pause',display:"flex" },
   // { label:isChecked? "Manual":"Automatica", callback: () => handleCheckboxChange(),icon:isChecked?'pan_tool_alt':'precision_manufacturing',display:"flex" },
];
const handleCheckboxChange =() => {

  if (isChecked)
      setIsChecked(false);
   else
     setIsChecked(true);
};
   /*const canvasRefo = useRef(null);

  useEffect(() => {
    const canvas = canvasRefo.current;
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.crossOrigin = "anonymous";  // Esto es necesario si la imagen requiere CORS
    img.src = photorigin;

    img.onload = () => {
      ctx.drawImage(img, 0, 0);
    };
  }, [photorigin]);*/
  return (
    <div className='item-row'> 
      <div className="section-top">
      <p className='title' style={{marginBottom:'-1px',marginTop:'-5px'}}>{statecam? "Foto Identificacion":"Foto Rostro"}</p>
        {/* <div className='camera-container'> */}
        {!photorigin || statecam ?(<div ref={containerRef} style={{ position: 'relative', height: `${videoHeight}px`, width: `${videoWidth}px`, borderRadius: '10px' }}>
          <video ref={videoRef} autoPlay muted height={videoHeight} width={videoWidth}   style={{ position: 'absolute', top: '0', left: '0', borderRadius: '10px', zIndex: 1 }} />
          <canvas ref={canvasRef} style={{ position: 'absolute', top: '0', left: '0', zIndex: 2 }} />
          {/* </div> onPlay={handleVideoOnPlay}*/}
        </div>):
        (<div ref={containerRef} style={{ position: 'relative', height: `${videoHeight}px`, width: `${videoWidth}px`, borderRadius: '10px' }}>
        <img src={photorigin} style={{ position: 'absolute', top: '0', left: '0', zIndex: 2, height: `${videoHeight}px`, width: `${videoWidth}px`, borderRadius: '10px' }} />
        {/*<canvas ref={canvasRefo} style={{ position: 'absolute', top: '0', left: '0', zIndex: 2 }}></canvas>*/}
        </div>)}
      </div>
      <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                {actions.map((action, index) => (
                    <Tooltip key={index} title={action.label} arrow>
                    <IconButton onClick={action.callback} style={{ display:action.display,marginRight: '15px', flexDirection: 'column', alignItems: 'center',borderRadius:'8px',color: '#0087F7'}}>
                        <Icon>{action.icon}</Icon>
                        <Typography variant="caption" style={{ marginTop: '4px',color:'rgb(40, 121, 177)',fontWeight:'bold'}}>
                                {''}
                            </Typography>
                    </IconButton>
                </Tooltip>
                ))}
            </div>

    </div>
  );
}

export default forwardRef(Camera);
