// Loading.js
import React from 'react';
import './App.css';

const Loading = ({text}) => {
  return (
    <div className="loading-container" style={{ position: 'fixed', zIndex: 1000, top: 0, bottom: 0, left: 0, right: 0 }}>
      <div className="loading-spinner"></div>
      <h4>{text}</h4>
    </div>
  );
};

export default Loading;
