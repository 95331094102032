import React ,{ useState } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';
// import innova from '/innova.png';  //

function Login() {
  let navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
  
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };
  
    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
    };

    function handleSubmit(event) {
        event.preventDefault();
        navigate("/app");
      }
      const validateEmail = (email) => {
        if(email==="")
         return true
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,2,3,4,5,6,7,8,9}\.])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      }

  return (
    <div className="outer-container">
      <div className="container">
        <div className="left-section">
          {/* Aquí puedes cambiar la imagen de fondo a la que desees */}
        </div>
        <div className="right-section">
        <div className="form-container">
        <form className="login-form" onSubmit={handleSubmit}>
            <div className='item-row '>
        {/* <img style={{width:'80px',height:'50px'}} src="/innova.png"></img> */}
       {/* {console.log(process.env.REACT_APP_ICON + 'innova.png')} */}

        {/* <img style={{width:'80px',height:'50px'}} src={process.env.REACT_APP_ICON + 'innova.png'}></img> */}
        <img style={{width:'80px',height:'50px'}} src={'/innova.png'}></img>
        <h2>Face Auth</h2>
        </div>
          <h3>Bienvenido</h3>
          <div className="input-group">
            <input 
              type="email" 
              name="email" 
              value={email} 
              onChange={handleEmailChange}
              required
              placeholder="&#xf007; Email"
              className={validateEmail(email) ? '' : 'invalid'}
            />
          </div>
          <div className="input-group">
            <input 
              type="password" 
              name="password" 
              value={password} 
              onChange={handlePasswordChange}
              required
              placeholder="&#xf023; Contraseña"
            />
          </div>
          <input className="submit-button" type="submit" value="Iniciar sesión" />
        </form>
      </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
