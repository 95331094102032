//import logo from './logo.svg';
import './App.css';
import MyDropzone from './MyDropzone';
import Camera from './Camera';
import { MdFace,MdFaceRetouchingOff  } from 'react-icons/md';
import React , { useState,useRef,useEffect }  from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Loading from './Loading';
import {IconButton,Tooltip,Icon,Typography} from '@mui/material';

const MySwal = withReactContent(Swal);


function App() {
  const videoHeight = 380;
  const videoWidth = 540;
  let camRef = useRef();
  const [photo, setphoto] = useState(null);
  const [filedocum, setfiledocum] = useState(null);

  const [showLoading, setShowLoading] = useState(false);
  const [showLoadinginit, setShowLoadinginit] = useState(false);

  const [origin, setorigin] = useState();
  const [data, sedata] = useState({nit:'2', idc:'', existe:false, docum:'',extdocum:'',face:''});

  const [photorigin, setphotorigin] = useState(null);

  const[statecam,setstatecam]=useState(false);
  
  const [changefile,setchangefile]=useState(false);
  const sphoto=(url)=>{
    setchangefile(true);
    setphoto(url);
    setphotorigin(url);
  }
  const sdocum=(file)=>{
    setchangefile(true);
    setfiledocum(file);
    //poner a detectar caras
    setstatecam(false);
    camRef.current.takenewd();
  }
  const showAlert = (sucess,message) => {
    setShowLoading(false);
   if (sucess.toLowerCase() === 'true' ? true : false){
    MySwal.fire({
      title: 'Verificacion Terminada',
      html: <p>Verificacion Correcta <MdFace size={36} /></p>,
      icon: 'success',
      confirmButtonText: 'Ok'
    });
   }else{
    MySwal.fire({
      title: 'Verificacion Terminada',
      html: <p>Verificacion Incorrecta {message} <MdFaceRetouchingOff size={36} /></p>,
      icon: 'error',
      confirmButtonText: 'Ok'
    });

   }
   setphoto(null);
   setfiledocum(null);
   if(!photorigin)
   camRef.current.takepausa();
   else
   setphotorigin(null);
  }
  function fileToArrayBuffer(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = event => resolve(reader.result);
      reader.onerror = error => reject(error);
      reader.readAsArrayBuffer(file);
    });
  }
  const Validateface = () => {

    if(photo!=null&&filedocum!=null&&photo!==''){
      setShowLoading(true);
    fetch(photo)
    .then(res => res.blob()) // Obtienes el blob del src de la imagen
    .then(blob => {
      const formData = new FormData();
      const file1 = new File([blob], 'filename.jpg', {type: 'image/jpeg'});
      const file = filedocum;
      formData.append('file', file);
      formData.append('file1', file1);

      fetch('https://autentificacionidentidad.servicein.cloud/Verifyface?customer='+data.nit+'&idc='+data.idc, {
          method: 'POST',
          body: formData,
      })
      .then(response => response.json())
      .then(data => {
         // console.log(data.Verificado);
            showAlert(data.Verificado,data.message);
            data.changefile=changefile;
            if (changefile){
              Promise.all([
                fileToArrayBuffer(file),
                fileToArrayBuffer(file1)
              ])
              .then(([arrayBuffer1, arrayBuffer2]) => {
                data.docum=arrayBuffer1;
                data.face=arrayBuffer2;
                window.opener.postMessage({completed:true,data:data}, origin);
              })
              .catch(error => {
                console.error('Ocurrió un error:', error);
              });
            }else{
              //console.log(origin);
              window.opener.postMessage({completed:true,data:data}, origin);
            }
      })
      .catch(error => {
        console.error('Ocurrió un error:', error);
      });
    });
  }else{
    MySwal.fire({
      title: 'Validación',
      html: <p>Por favor toma la foto y adjunta el documento</p>,
      icon: 'error',
      confirmButtonText: 'Ok'
    });
  }
  };
  useEffect(() => {
    function handleMessage(event) {
      //console.log(event.origin,111);
    //if (!event.origin.includes('')) return; // Verificar origen
    if (event.origin == 'https://aut.servicein.cloud') return; // Verificar origen
    if (event.origin == 'http://localhost:3000') return; // Verificar origen
      //console.log(event.origin);
      //const { nit, idc, existe, docum,face} = event.data;
      sedata(event.data);
      setorigin(event.origin);
      setShowLoadinginit(true);
     // event.source.postMessage({ ready: true }, event.origin);
    }
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
  /*useEffect(() => {   
    //console.log(origin,1234);
    if(origin!='null'&&origin!=undefined)
       {
        //si la aplicacion padre lo abre desde un open 
        //window.opener.postMessage({ ready: true }, origin);
         //si la aplicacion padre lo abre desde un iframe
        // window.parent.postMessage({ ready: true }, origin);
      }
  }, [origin]);*/

  const funcext =(extension)=>{
    let mimeType = '';
    let fileName = '';
    switch (extension.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
        mimeType = 'image/jpeg';
        fileName = 'documento.jpg';
        break;
      case 'png':
        mimeType = 'image/png';
        fileName = 'documento.png';
        break;
      case 'pdf':
        mimeType = 'application/pdf';
        fileName = 'documento.pdf';
        break;
      default:
        console.error("Extensión no soportada");
        return;
    }
    return {mimeType:mimeType,fileName:fileName};
  }
  useEffect(() => {   
    if(showLoadinginit)
       {
        if(data.docum){
          // fetch(event.data.docum)
          // .then(response => response.arrayBuffer())
          // .then(arrayBuffer => {
           let typedoc=funcext(data.extdocum);
           const blob = new Blob([data.docum], { type: typedoc.mimeType });
           const file = new File([blob], typedoc.fileName, { type: typedoc.mimeType });
           setfiledocum(file);
         //  })
         //  .catch(error => {
         //  console.error('Error al obtener el ArrayBuffer:', error);
         //  });
       }
       if(data.face){
         const blob = new Blob([data.face], { type: 'image/png' });  // Cambia 'image/png' al tipo de archivo real si es necesario
         const url = URL.createObjectURL(blob);
         setphoto(url);
         setphotorigin(url);
       }
        setShowLoadinginit(false);
      }
  }, [showLoadinginit,data]);
  const takestatecam=()=>{
    if (statecam){
    setstatecam(false);
  }
    else{
      setstatecam(true);
      camRef.current.takenewd();
    }
  }
  return (
    <div className="App">
      {showLoading && <Loading text="Verificando identidad"/>}
      {showLoadinginit && <Loading text=""/>}
      <div className="outer-containerapp">
      {!showLoadinginit && <div className="containerapp">
      <div className="left-sectionapp">
       <MyDropzone filedocum={sdocum} fileorigin={filedocum} takestatecam={takestatecam} statecam={statecam}></MyDropzone>
        </div>
        <div className="right-sectionapp">
        {(<Camera filedocum={sdocum} ref={camRef}  photo={sphoto} photorigin ={photorigin} setphotorigin={setphotorigin} statecam={statecam}> </Camera>)}
        </div>
      </div>}
      <div className='footer'>
     {/*<button onClick={Validateface}  className='button'>
      <MdFace />
  </button>*/}
      <Tooltip key={0} title={'Validar'} arrow>
        <IconButton onClick={Validateface} style={{ display:'flex',marginRight: '15px', flexDirection: 'column', alignItems: 'center',borderRadius:'8px',color: '#0087F7'}}>
            <Icon style={{ fontSize: '60px' }}>{'face'}</Icon>
            <Typography variant="caption" style={{ marginTop: '4px',color:'rgb(40, 121, 177)',fontWeight:'bold'}}>
                    {''}
                </Typography>
        </IconButton>
    </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default App;
